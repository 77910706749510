
.rotation{
    -webkit-animation: spin 15s linear infinite;
    animation: spin 15s linear infinite;
}



@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  @keyframes slide {
    from{
      transform: translateX(0);
    }
    to {
       transform: translateX(-100%);
    }
  }

  .doctors-slide{
    animation: 12s slide infinite linear;
  }

  .doctors:hover .doctors-slide {
    animation-play-state: paused;
  }

  .logos-slide {
    animation: 12s slide infinite linear;

  }

  .logos:hover .logos-slide {
    animation-play-state: paused;
  }

  .letters {
    animation: 11s slide infinite linear;
  }

 .letters:hover .letters-slide {
  animation-play-state: paused;
 }

 
  .brightness-50 {
    --tw-brightness: brightness(.5);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

:where(span,h2,h3,h4,h6,p)::selection{
  background-color: #014B11;
  color: white;
}

/* p::selection {
 background-color: #014B11;
 color: white;
} */
 

 

